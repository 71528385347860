<template>
  <div class="wrapper">
    <div v-if="list_instr">
      <div class="input">
        <input
          type="text"
          class="form-control col-md-4 shadow-none"
          placeholder="поиск по наименованию или коду"
          @input="filter"
          v-model="search_input"
        />
        <button
          class="btn btn-sm btn-outline-primary shadow-none"
          @click="add_instr"
        >
          добавить
        </button>
      </div>
      <table class="table table-sm">
        <tr>
          <th></th>
          <th>наименование</th>
          <th>артикул</th>
          <th>код</th>
          <th>расценки</th>
          <th></th>
        </tr>
        <tr v-if="add">
          <td></td>
          <td>
            <input
              type="text"
              class="form-control shadow-none"
              size="1"
              placeholder="наименование"
              v-model.trim="add_name"
            />
          </td>
          <td>
            <input
              type="text"
              class="form-control shadow-none"
              size="1"
              placeholder="артикул"
              v-model.trim="add_art"
            />
          </td>
          <td>
            <input
              type="text"
              class="form-control shadow-none"
              size="1"
              placeholder="код"
              v-model.trim="add_cod"
            />
          </td>
          <td>
            <input
              type="number"
              class="form-control shadow-none"
              size="1"
              placeholder="расценки"
              v-model.trim="add_price_group"
            />
          </td>
          <td>
            <button
              class="btn btn-sm btn-outline-success shadow-none m-1"
              @click="save_new_instr"
              :disabled="disabled"
            >
              сохранить
            </button>
            <button
              class="btn btn-sm btn-outline-danger shadow-none m-1"
              @click="add_instr"
            >
              отмена
            </button>
          </td>
        </tr>
        <tr
          v-for="(item, idx) in list_instr"
          :key="idx"
          @dblclick="set_price_group(item.cod)"
        >
          <td>{{ idx + 1 }}</td>
          <td>{{ item.name_instr }}</td>
          <td>{{ item.art }}</td>
          <td>{{ item.cod }}</td>
          <td>{{ item.price_group }}</td>
          <td class="text-right">
            <button
              class="btn btn-sm btn-outline-danger shadow-none m-1"
              @click="del_instr(item)"
            >
              удалить
            </button>
            <button
              v-if="USER_INFO.gruppa === 'super'"
              class="btn btn-sm btn-outline-primary shadow-none m-1"
              @click="edit_instr(item)"
            >
              изменить
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
import { mapGetters } from "vuex";

export default {
  name: "UploadInstr",
  computed: {
    ...mapGetters(["USER_INFO"]),
    disabled: function() {
      if (
        this.add_name.length > 0 &&
        this.add_art.length > 0 &&
        this.add_cod.length > 0
      ) {
        return false;
      } else {
        return true;
      }
    }
  },
  data: () => ({
    list_instr: null,
    filter_list_instr: null,
    search_input: "",
    add: false,
    add_name: "",
    add_art: "",
    add_cod: "",
    add_price_group: 1,
    update: false,
    old_cod: null
  }),
  mounted() {
    this.get_data();
  },
  methods: {
    get_data() {
      HTTPGET({
        url: "admin/instr/get_list_instr.php",
        params: {
          list: true
        }
      }).then(
        response => (this.list_instr = this.filter_list_instr = response.data)
      );
    },
    filter() {
      this.list_instr = this.filter_list_instr.filter(
        item =>
          item.name_instr
            .toLowerCase()
            .includes(this.search_input.toLowerCase()) ||
          item.cod.toLowerCase().includes(this.search_input.toLowerCase())
      );
    },
    add_instr() {
      this.add = !this.add;
      if (!this.add) {
        this.add_name = "";
        this.add_art = "";
        this.add_cod = "";
        this.update = false;
        this.old_cod = null;
      }
    },
    save_new_instr() {
      const i = this.filter_list_instr.filter(item =>
        item.cod.toLowerCase().includes(this.add_cod.toLowerCase())
      );
      let url;
      if (i.length > 0 && !this.update) {
        alert(`позиция с кодом ${this.add_cod} уже существует`);
      } else {
        if (!this.update) {
          url = "admin/instr/add_instr.php";
        } else {
          url = "admin/instr/edit_instr.php";
        }
        HTTPGET({
          url,
          params: {
            name: this.add_name,
            art: this.add_art,
            cod: this.add_cod,
            old_cod: this.old_cod,
            price_group: this.add_price_group
          }
        })
          .then(() => this.get_data())
          .then(() => this.add_instr());
      }
    },
    del_instr(item) {
      if (confirm(`удалить ${item.name_instr} код ${item.cod}`)) {
        HTTPGET({
          url: "admin/instr/del_instr.php",
          params: {
            cod: item.cod
          }
        }).then(() => this.get_data());
      }
    },
    edit_instr(item) {
      this.add = !this.add;
      if (!this.add) {
        this.add_name = "";
        this.add_art = "";
        this.add_cod = "";
        this.update = false;
        this.old_cod = null;
      } else {
        window.scrollTo(0, 0);
        this.update = true;
        this.add_name = item.name_instr;
        this.add_art = item.art;
        this.add_cod = item.cod;
        this.old_cod = item.cod;
        this.add_price_group = item.price_group;
      }
    },
    set_price_group(cod) {
      console.log(cod);
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  padding: 10px;
}
td,
th {
  font-size: 0.7em;
  vertical-align: middle;
}
.btn {
  font-size: 0.8em;
}
.input {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  gap: 5px;
}
input {
  font-size: 1em;
}
</style>
